<template>
  <b-card title="companyVisits">
    <b-card-body v-if="visits.length <= 0">
      {{ $t("xNotFound1", { value: $t("visit") }) }}</b-card-body
    >
    <visit-list
      v-else
      :visits.sync="visits"
      @fetchvisit="fetchVisits"
    ></visit-list>
  </b-card>
</template>

<script>
import VisitList from "../components/VisitList.vue";
import { BCard, BCardBody } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    VisitList,
  },
  data: function () {
    return {
      visits: [],
    };
  },
  async mounted() {
    this.fetchVisits();
  },
  methods: {
    async fetchVisits() {
      this.visits = await this.$store.dispatch("visitsModule/fetchVisits");
    },
  },
};
</script>
